<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Laporan </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div class="row row-condensed-mini">
              <div class="as-col col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Perusahaan</b>
                      <v-select
                        :options="list_perusahaan"
                        label="p_nama"
                        v-model="perusahaan_selected"
                        @input="changePerusahaan"
                        placeholder="Perusahaan..."
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md-3">
                    <div class="form-group">
                      <b for="">Nomor SIP</b>
                      <v-select
                        :options="list_no_sip"
                        label="pr_nomor_sk"
                        v-model="no_sip_selected"
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md-3">
                    <div class="form-group">
                      <b for="">Kelompok</b>
                      <v-select
                        :options="list_tanaman"
                        label="tk_nama"
                        v-model="kelompok_selected_tanaman"
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md-3">
                    <div class="form-group">
                      <b for="">Sub Kelompok</b>
                      <v-select
                        :options="
                          kelompok_selected_tanaman.tanaman_kategori_sub
                        "
                        label="tks_nama"
                        v-model="subkelompok_selected_tanaman"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="as-col col-md-3">
                    <div class="form-group">
                      <b for=""
                        >Jenis Tanaman <span class="text-danger">*</span></b
                      >
                      <v-select
                        :options="subkelompok_selected_tanaman.tanaman"
                        label="t_nama"
                        v-model="tanaman_selected"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="as-col col-md">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-5 d-flex justify-content-start"
                    style="margin-bottom: 10px; gap: 10px"
                  >
                    <div class="btn btn-sm btn-primary" v-on:click="showData">
                      <i class="fa fa-filter"></i> Filter
                    </div>

                    <div class="btn btn-sm btn-success" v-on:click="getExcel">
                      <i class="fa fa-download"></i> Excel
                    </div>
                    <div
                      class="btn btn-sm btn-danger"
                      v-on:click="modalFilterPDF = true"
                    >
                      <i class="fa fa-download"></i> {{ buttonLabel }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CRow>
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="12">
                                        <template>
                                            <div v-if="show_tables">
                                                <template>
                                                    <div v-if="loadingTable">
                                                        <i class="fa fa-spin fa-loading"></i> Loading...
                                                    </div>
                                                    <div  v-else>
                                                        
                                                        <table class="table table-bordered table-sm w-100" id="table-benih">
                                                            <thead class="bg-sapphire">
                                                                <tr>
                                                                    <th style="width: 15px">No</th>
                                                                    <th>NIB</th>
                                                                    <th>Nama Perusahaan</th>
                                                                    <th>Kelompok Jenis Tanaman</th>
                                                                    <th>Sub Kelompok Jenis Tanaman</th>
                                                                    <th>Jenis Tanaman</th>
                                                                    <th>Nama Latin</th>
                                                                    <th>Nama Varietas</th>
                                                                    <th>Bentuk Benih</th>
                                                                    <th>Jumlah</th>
                                                                    <th>Satuan</th>
                                                                    <th>Nilai (Total Harga dalam USD)</th>
                                                                    <th>Kode HS</th>
                                                                </tr>
                                                            </thead>
                                                            <template>
                                                                <tbody v-if="reslistlaporan.length != 0">
                                                                    <tr v-for="(value_benih, index_benih) in reslistlaporan" :key="index_benih">
                                                                        <td>
                                                                            <template>
                                                                                <div v-if="index_benih+1 == 10">
                                                                                    {{curent_page}}0
                                                                                </div>
                                                                                <div v-else>
                                                                                    <template>
                                                                                        <span v-if="curent_page-1 != 0">
                                                                                            {{curent_page-1}}{{index_benih+1}}
                                                                                        </span>
                                                                                        <span v-else>
                                                                                            {{index_benih+1}}
                                                                                        </span>
                                                                                    </template>
                                                                                </div>
                                                                            </template>
                                                                        </td>
                                                                        <td>{{value_benih.permohonan.nib}}</td>
                                                                        <td>{{value_benih.permohonan.perusahaan.p_nama}}</td>
                                                                        <td>{{value_benih.tanaman.tanaman_kategori_nama}}</td>
                                                                        <td>{{value_benih.tanaman.tanaman_kategori_sub_nama}}</td>
                                                                        <td>{{value_benih.tanaman.t_nama}}</td>
                                                                        <td>{{value_benih.pb_nama_latin}}</td>
                                                                        <td>{{value_benih.pb_varietas}}</td>
                                                                        <td>{{value_benih.bentuk_benih.bb_nama}}</td>
                                                                        <td>{{value_benih.pb_jumlah_disetujui}}</td>
                                                                        <td>{{value_benih.satuan.s_nama}}</td>
                                                                        <td>{{value_benih.pb_nilai_total}}</td>
                                                                        <td>{{value_benih.pb_kode_hs}}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody v-else>
                                                                    <tr>
                                                                        <td colspan="8">Data Benih Tidak Ditemukan</td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                                
                                                        </table>
                                                    </div>
                                                    
                                                </template>
                                                
                                                <nav aria-label="pagination">
                                                    <ul class="pagination justify-content-start">
                            <!-- <li v-for="(page, key_page) in paging" v-bind:key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
                                                            <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                                <div v-html="page.label"></div>
                                                            </div>
                                                        </li> -->
                            <li
                              v-for="(page, key_page) in paging"
                              :key="key_page"
                              :class="[
                                'page-item',
                                { active: page.active, disabled: !page.url },
                              ]"
                            >
                              <a
                                href="#"
                                @click.prevent="page.url && toPage(page.url)"
                                class="page-link"
                              >
                                <span v-html="page.label"></span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div v-else>
                        <template>
                          <div v-if="loadingTable">
                            <i class="fa fa-spin fa-loading"></i> Loading...
                          </div>
                          <div class="alert alert-info" v-else>
                            Untuk menampilkan data, filter data terlebih dahulu
                          </div>
                        </template>
                      </div>
                    </template>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
            <!-- form Filter PDF -->
            <template>
              <div>
                <CModal
                  title="Filter data"
                  color="btn-primary"
                  :show.sync="modalFilterPDF"
                  size="md"
                >
                  <CRow>
                    <CCol md="12">
                      <div class="form-group">
                        <b for="">Tgl Dari</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="start_pdf"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                      <div class="form-group">
                        <b for="">Tgl Sampai</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="end_pdf"
                          :disabled-dates="disabledDates"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                      <!-- <div class="form-group">
                        <b for="">Perusahaan</b>
                        <v-select
                          :options="list_perusahaan"
                          label="p_nama"
                          v-model="perusahaan_pdf_selected"
                          @input="changePerusahaanModal"
                          placeholder="Perusahaan..."
                        ></v-select>
                      </div>
                      <div class="form-group">
                        <b for="">Nomor SIP</b>
                        <v-select
                          :options="list_no_sip"
                          label="pr_nomor_sk"
                          v-model="no_sip_pdf_selected"
                        ></v-select>
                      </div>

                      <div class="form-group">
                        <b for="">Kelompok</b>
                        <v-select
                          :options="list_tanaman"
                          label="tk_nama"
                          v-model="kelompok_selected_tanaman_pdf"
                        ></v-select>
                      </div>

                      <div class="form-group">
                        <b for="">Sub Kelompok</b>
                        <v-select
                          :options="
                            kelompok_selected_tanaman_pdf.tanaman_kategori_sub
                          "
                          label="tks_nama"
                          v-model="subkelompok_selected_tanaman_pdf"
                        ></v-select>
                      </div>
                      <div class="form-group">
                        <b for=""
                          >Jenis Tanaman <span class="text-danger">*</span></b
                        >
                        <v-select
                          :options="subkelompok_selected_tanaman_pdf.tanaman"
                          label="t_nama"
                          v-model="tanaman_selected_pdf"
                          :value="$store.myValue"
                        ></v-select>
                      </div> -->
                    </CCol>
                  </CRow>
                  <template #footer>
                    <CButton @click="modalFilterPDF = false" color="secondary"
                      >Tutup</CButton
                    >
                    <CButton
                      v-on:click="getPDFLink()"
                      color="primary"
                      :disabled="isDownloading"
                      >{{ buttonLabel }}</CButton
                    >
                  </template>
                </CModal>
              </div>
            </template>

            <!-- form Filter PDF selesai -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
    const datalaporan = [];
    export default {
        name: "LaporanPengeluaranDataBenih",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                reslistlaporan: datalaporan,
                paging: [],
                
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                nib: "",
                show_tables: false,
                tanaman_selected: '',
                filter_status: [
                    {
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                ],
                pr_kategori: [],
                filter_module: [
                    {
                        value: "Hortikultura",
                        label: "Hortikultura",
                    },
                    {
                        value: "Sumber Daya Genetik",
                        label: "Sumber Daya Genetik",
                    },
                    {
                        value: "Tanaman Pangan",
                        label: "Tanaman Pangan",
                    },
                    {
                        value: "Perkebunan",
                        label: "Perkebunan",
                    },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
                ],
                curent_page: '',
                loadingTable: false,
                list_tanaman: [],
                kelompok_selected_tanaman: [],
                subkelompok_selected_tanaman: [],
                list_perusahaan:[],
				perusahaan_selected: {
					nib: "semua",
					p_nama: "-- Pilih Data --",
				},
                list_no_sip: [],
				no_sip_selected: {
					id: "semua",
					pr_nomor_sk: "-- Pilih Data --",
				},
      // for filter pdf
      list_no_sip_modal: [],
      buttonLabel: "Export PDF",
      isDownloading: false,
      modalFilterPDF: false,
      start_pdf: "",
      end_pdf: "",
      perusahaan_pdf_selected: {
        nib: "semua",
        p_nama: "-- Pilih Data --",
      },
      no_sip_pdf_selected: {
        id: "semua",
        pr_nomor_sk: "-- Pilih Data --",
      },
      kelompok_selected_tanaman_pdf: [],
      subkelompok_selected_tanaman_pdf: [],
      tanaman_selected_pdf: "",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        from: new Date(8640000000000000),
        customPredictor: (date) => {
          if (!this.start_pdf) {
            return false;
          }
          const start = new Date(this.start_pdf);
          const end = new Date(this.start_pdf);
          end.setDate(start.getDate() + 31);

          return date < start || date > end;
        },
      },
            };
        },
        methods: {
            showData(){
                
                this.loadingTable = true;
                axios
                    .get(this.apiLink + "api/permohonan/list-benih", {
                        params:{
                            nib: this.perusahaan_selected?.nib,
                            permohonan_id: this.no_sip_selected?.id,
                            kelompok: this.kelompok_selected_tanaman?.id,
                            sub_kelompok: this.subkelompok_selected_tanaman?.id,
                            tanaman: this.tanaman_selected?.id,
                            is_pemasukan: 'true',
                            start: this.start,
                            end: this.end,
                            status: '',
                            pr_kategori: this.pr_kategori,
                        },
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_laporan = response.data;
                        var res_laporan_data = res_laporan.data;
                        // console.log(res_laporan_data);
                        if (res_laporan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_laporan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.show_tables = true;
                            this.reslistlaporan = res_laporan_data.data.data;
                            this.excel_url = res_laporan_data.excel;
                            console.log(this.reslistlaporan);
                            this.paging = res_laporan_data.data.links;
                            // this.show_data_from = res_laporan_data.from;
                            // this.show_data_to = res_laporan_data.list.to;
                            // this.total_data = res_laporan_data.list.total;
                            this.curent_page = res_laporan_data.data.current_page;
                        }
                    });
            },
            toPage(url) {
                this.loadingTable = true;
                axios.get(url, {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_laporan = response.data;
                    var res_laporan_data = res_laporan.data;
                    // console.log(res_laporan_data);
                    if (res_laporan.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_laporan.data.message,
                        });
                    } else {
                        this.loadingTable = false;
                        this.show_tables = true;
                        this.reslistlaporan = res_laporan_data.data.data;
                        this.excel_url = res_laporan_data.excel;
                        console.log(this.reslistlaporan);
                        this.paging = res_laporan_data.data.links;
                        // this.show_data_from = res_laporan_data.from;
                        // this.show_data_to = res_laporan_data.list.to;
                        // this.total_data = res_laporan_data.list.total;
                        this.curent_page = res_laporan_data.data.current_page;
                    }
                });
            },
            getExcel(){
      var win = window.open(this.excel_url, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    changePerusahaan() {
      axios
        .get(this.apiLink + "api/master/no_sip", {
          params: {
            nib: this.perusahaan_selected?.nib,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_no_sip = response.data;
          var res_no_sip_data = res_no_sip.data;
          if (res_no_sip.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_no_sip.data.message,
            });
          } else {
            this.list_no_sip = res_no_sip_data.master;
            this.list_no_sip.unshift({
              id: "semua",
              pr_nomor_sk: "-- Pilih Data --",
            });
            // console.log(res_tanaman_data.master);
          }
        });
    },
    changePerusahaanModal() {
      axios
        .get(this.apiLink + "api/master/no_sip", {
          params: {
            nib: this.perusahaan_selected?.nib,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_no_sip = response.data;
          var res_no_sip_data = res_no_sip.data;
          if (res_no_sip.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_no_sip.data.message,
            });
          } else {
            this.list_no_sip_modal = res_no_sip_data.master;
            this.list_no_sip_modal.unshift({
              id: "semua",
              pr_nomor_sk: "-- Pilih Data --",
            });
            // console.log(res_tanaman_data.master);
          }
        });
    },
    getPDFLink() {
      if (this.start_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      } else if (this.end_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      }

      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "";
      if (this.perusahaan_selected.nib != "semua") {
        fileName =
          "Laporan Benih Permohonan " +
          this.perusahaan_selected.p_nama +
          ".pdf";
      } else {
        fileName = "Laporan Benih Permohonan.pdf";
      }

      axios
        .get(this.apiLink + "api/pdf-list-benih", {
          params: {
            nib: this.perusahaan_selected?.nib,
            permohonan_id: this.no_sip_selected?.id,
            kelompok: this.kelompok_selected_tanaman?.id,
            sub_kelompok: this.subkelompok_selected_tanaman?.id,
            tanaman: this.tanaman_selected?.id,
            is_pemasukan: "true",
            start: this.start_pdf,
            end: this.end_pdf,
            status: "",
            pr_kategori: this.pr_kategori,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
  beforeCreate() {
    axios.get(this.apiLink + "api/master/tanaman").then((response) => {
      var res_tanaman = response.data;
      var res_tanaman_data = res_tanaman.data;
      if (res_tanaman.meta.code != "200") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res_tanaman.data.message,
        });
      } else {
        this.list_tanaman = res_tanaman_data.master;
        // console.log(res_tanaman_data.master);
      }
    });
    axios
      .get(this.apiLink + "api/master/perusahaan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan = response.data;
        var res_perusahaan_data = res_perusahaan.data;
        if (res_perusahaan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan.data.message,
          });
        } else {
          this.list_perusahaan = res_perusahaan_data.master;
          this.list_perusahaan.unshift({
            nib: "semua",
            p_nama: "-- Pilih Data --",
          });
          // console.log(res_tanaman_data.master);
        }
      });
  },
  mounted() {
    $(".no-readonly").prop("readonly", false);
  },
  watch: {
    start_pdf(val) {
      if (val) {
        const start = new Date(val);
        const end = new Date(start);
        end.setDate(start.getDate() + 31);

        this.disabledDates = {
          ranges: [
            {
              from: new Date(-8640000000000000),
              to: new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate()
              ),
            },
            {
              from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
              to: new Date(8640000000000000),
            },
          ],
        };
      }
    },
  },
};
</script>